import ApiService from '@/common/api/api.service';
import helpers from '../../utils/helpers';

const CedenteService = {
  objectToQueryString(obj) {
    var str = [];
    for (var p in obj) {
      if (obj[p] !== null) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }

    return str.join('&');
  },
  listarBoletos(form) {
    if (form.CpfCnpjSacado)
      form.CpfCnpjSacado = helpers.removerMascara(form.CpfCnpjSacado);

    // form.CpfCnpjCedente = form.CpfCnpjCedente ? helpers.removerMascara(form.CpfCnpjCedente) : '';
    let sendForm = this.objectToQueryString(form);
    return ApiService.get(`/CedenteBoletos/listar-boletos?${sendForm}`);
  },
  listarBoletosHtmlExcel(form) {
    if (form.CpfCnpjSacado)
      form.CpfCnpjSacado = helpers.removerMascara(form.CpfCnpjSacado);

    let sendForm = this.objectToQueryString(form);
    return ApiService.get(`/CedenteBoletos/listar-boletos/html-excel?${sendForm}`);
  },
  obterBoletos(form) {
    return ApiService.post(`/CedenteBoletos/obter-boleto`, form);
  },
};

export default CedenteService;
